import { Box, Stack, Typography } from '@mui/material';
import {
  AssessmentForGroup,
  DATE_FORMAT_FULL_MONTH_FNS,
  SORT_DIRECTION,
  useGetAssessmentsForGroupQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { GridViewIcon, Loading, ModalSearch, PlusIcon } from '@schooly/style';
import { useSelectIdsContext } from '@schooly/utils/bulk-actions';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { Counter } from '../../../../components/uikit/Counter/Counter.styled';
import { SimpleButton } from '../../../../components/uikit/SimpleButton/SimpleButton';
import { ListViewAssessments } from '../../../../components/uikit-components/ListViewAssessments/ListViewAssessments';
import {
  ModalContentStickyTitle,
  ModalContentStickyWrapper,
} from '../../../../components/uikit-components/Modal/Modal.styled';
import { MONTH_NONE } from '../../../../constants/misc';
import { useGroup } from '../../../../context/groups/useGroup';
import { GroupTabEmptyStub } from '../GroupTabEmptyStub';
import {
  GroupPreviewModalAssessmentsMonthName,
  GroupPreviewModalAssessmentsMonths,
} from './GroupPreviewModalAssessments.styled';

export const GroupPreviewModalAssessments: FC = () => {
  const { formatMessage } = useIntl();
  const { group, groupId = '' } = useGroup();
  const [query, setQuery] = useState('');
  const { permissions, relationId } = useAuth();

  const canCreate =
    permissions?.includes('assessment_manager') ||
    (permissions?.includes('assessment_creator') &&
      group?.staff.some((u) => u.user.relation_id === relationId));

  const { data, isLoading: fetching } = useGetAssessmentsForGroupQuery(
    {
      groupId,
      query,
      sort: [
        { columnTextId: 'assessment_date', direction: SORT_DIRECTION.DESC },
        { columnTextId: 'name', direction: SORT_DIRECTION.ASC },
      ],
    },
    { refetchOnMount: 'always', enabled: !!groupId },
  );

  const assessments = data || [];
  const count = assessments.length;

  const assessmentsByMonths = useMemo(() => {
    return (data || []).reduce((prev, assessment) => {
      const month = assessment.assessment_date
        ? format(
            newDateTimezoneOffset(assessment.assessment_date),
            DATE_FORMAT_FULL_MONTH_FNS,
          ).toUpperCase()
        : MONTH_NONE;

      if (!prev[month]) {
        prev[month] = [];
      }

      prev[month].push(assessment);

      return prev;
    }, {} as Record<string, AssessmentForGroup[]>);
  }, [data]);

  const { selectedIds, toggleSelectId, clearSelectedIds } = useSelectIdsContext();

  const handleUpdateQuery = useCallback(
    (v: string) => {
      setQuery(v);
      clearSelectedIds();
    },
    [clearSelectedIds],
  );

  return (
    <ModalContentStickyWrapper>
      <ModalContentStickyTitle
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            '@media (orientation: portrait)': {
              paddingTop: 3,
            },
          },
        })}
      >
        <Stack
          direction="row"
          gap={2}
          justifyContent="space-between"
          mb={2.5}
          alignItems="center"
          sx={(theme) => ({
            flexWrap: 'wrap',
            [theme.breakpoints.only('md')]: {
              mb: assessments.length < 1 ? 0 : 2.5,
            },
          })}
        >
          <Typography variant="h2">
            <FormattedMessage id="section-Assessments" />
            {count > 1 && <Counter>{count}</Counter>}
          </Typography>

          <Stack
            direction="row"
            gap={2.5}
            sx={(theme) => ({
              [theme.breakpoints.down('md')]: {
                gap: theme.spacing(2),
              },
            })}
          >
            <ModalSearch
              value={query}
              onChange={handleUpdateQuery}
              withDebounce
              placeholder={formatMessage({ id: 'people-Search' })}
            />

            {count > 1 && (
              <Stack
                component={Link}
                sx={{ color: 'text.primary' }}
                justifyContent="center"
                to="./markbook"
              >
                <SimpleButton startIcon={<GridViewIcon />} color="inherit">
                  <FormattedMessage id="groups-Assessment-OpenMarkbook" />
                </SimpleButton>
              </Stack>
            )}
            {canCreate && (
              <Stack
                component={Link}
                sx={{ color: 'text.primary' }}
                justifyContent="center"
                to={{ pathname: '/assessments/new' }}
                state={{ selectedGroups: [group] }}
              >
                <SimpleButton startIcon={<PlusIcon />} color="inherit">
                  <FormattedMessage id="groups-Assessment-CreateAssessment" />
                </SimpleButton>
              </Stack>
            )}
          </Stack>
        </Stack>
      </ModalContentStickyTitle>
      {fetching ? (
        <Loading />
      ) : (
        <GroupPreviewModalAssessmentsMonths>
          {assessments.length < 1 && (
            <GroupTabEmptyStub textId="groups-NoResultsMatching-Assessment" />
          )}
          {Object.keys(assessmentsByMonths).map((month) => (
            <Box key={month}>
              {month !== MONTH_NONE && (
                <GroupPreviewModalAssessmentsMonthName>
                  {month}
                </GroupPreviewModalAssessmentsMonthName>
              )}
              <ListViewAssessments
                type="for-group"
                assessments={assessmentsByMonths[month]}
                id={group?.id}
                selectedIds={selectedIds}
                onSelectAssessment={toggleSelectId}
              />
            </Box>
          ))}
        </GroupPreviewModalAssessmentsMonths>
      )}
    </ModalContentStickyWrapper>
  );
};
